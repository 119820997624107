// AI ENGINE - CARD
export const AI_ENGINE_CARD_REQUEST = 'ai_engine/AI_ENGINE_CARD_REQUEST';
export const AI_ENGINE_CARD_RESPONSE = 'ai_engine/AI_ENGINE_CARD_RESPONSE';
export const AI_ENGINE_CARD_FAILURE = 'ai_engine/AI_ENGINE_CARD_FAILURE';

// AI ENGINE - CREATE CHAT HISTORY
export const AI_ENGINE_SEND_NEW_CHAT_REQUEST = 'ai_engine/AI_ENGINE_SEND_NEW_CHAT_REQUEST';
export const AI_ENGINE_SEND_NEW_CHAT_RESPONSE = 'ai_engine/AI_ENGINE_SEND_NEW_CHAT_RESPONSE';
export const AI_ENGINE_SEND_NEW_CHAT_FAILURE = 'ai_engine/AI_ENGINE_SEND_NEW_CHAT_FAILURE';

// AI ENGINE - GET CHAT HISTORY
export const AI_ENGINE_GET_CHAT_HISTORY_REQUEST = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_REQUEST';
export const AI_ENGINE_GET_CHAT_HISTORY_RESPONSE = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_RESPONSE';
export const AI_ENGINE_GET_CHAT_HISTORY_FAILURE = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_FAILURE';

// AI ENGINE - GET CHAT HISTORY INFO
export const AI_ENGINE_GET_CHAT_HISTORY_INFO_REQUEST = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_INFO_REQUEST';
export const AI_ENGINE_GET_CHAT_HISTORY_INFO_RESPONSE = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_INFO_RESPONSE';
export const AI_ENGINE_GET_CHAT_HISTORY_INFO_FAILURE = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_INFO_FAILURE';

// AI ENGINE - CREATE THREAD
export const AI_ENGINE_CREATE_THREAD_REQUEST = 'ai_engine/AI_ENGINE_CREATE_THREAD_REQUEST';
export const AI_ENGINE_CREATE_THREAD_RESPONSE = 'ai_engine/AI_ENGINE_CREATE_THREAD_RESPONSE';
export const AI_ENGINE_CREATE_THREAD_FAILURE = 'ai_engine/AI_ENGINE_CREATE_THREAD_FAILURE';

// AI ENGINE - UPDATE THREAD
export const AI_ENGINE_UPDATE_THREAD_REQUEST = 'ai_engine/AI_ENGINE_UPDATE_THREAD_REQUEST';
export const AI_ENGINE_UPDATE_THREAD_RESPONSE = 'ai_engine/AI_ENGINE_UPDATE_THREAD_RESPONSE';
export const AI_ENGINE_UPDATE_THREAD_FAILURE = 'ai_engine/AI_ENGINE_UPDATE_THREAD_FAILURE';

// AI ENGINE - LIST THREAD
export const AI_ENGINE_LIST_THREAD_REQUEST = 'ai_engine/AI_ENGINE_LIST_THREAD_REQUEST';
export const AI_ENGINE_LIST_THREAD_RESPONSE = 'ai_engine/AI_ENGINE_LIST_THREAD_RESPONSE';
export const AI_ENGINE_LIST_THREAD_FAILURE = 'ai_engine/AI_ENGINE_LIST_THREAD_FAILURE';

// AI ENGINE - DELETE THREAD
export const AI_ENGINE_DELETE_THREAD_REQUEST = 'ai_engine/AI_ENGINE_DELETE_THREAD_REQUEST';
export const AI_ENGINE_DELETE_THREAD_RESPONSE = 'ai_engine/AI_ENGINE_DELETE_THREAD_RESPONSE';
export const AI_ENGINE_DELETE_THREAD_FAILURE = 'ai_engine/AI_ENGINE_DELETE_THREAD_FAILURE';

// AI ENGINE - UPDATE CHAT HISTORY
export const UPDATE_CHAT_HISTORY = 'ai_engine/UPDATE_CHAT_HISTORY';

// AI ENGINE - THREAD ID
export const SET_THREAD_ID = 'ai_engine/SET_THREAD_ID';

// AI ENGINE - GENERATING MESSAGE
export const AI_ENGINE_GENERATING_MESSAGE = 'ai_engine/AI_ENGINE_GENERATING_MESSAGE';

// AI ENGINE - RESET
export const AI_ENGINE_RESET = 'ai_engine/AI_ENGINE_RESET';
