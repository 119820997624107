import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';

import {
  createAIEngineThreadFailure,
  createAIEngineThreadResponse,
  deleteAIEngineThreadFailure,
  deleteAIEngineThreadResponse,
  getAIEngineChatHistoryFailure,
  getAIEngineChatHistoryResponse,
  getAIEngineThreadListFailure,
  getAIEngineThreadListResponse,
  logoutRequest,
  sendAIEngineNewChatFailure,
  sendAIEngineNewChatResponse,
  aiEngineGeneratingMsg,
  updateChatHistory,
  getAIEngineChatHistoryInfoResponse,
  getAIEngineChatHistoryInfoFailure,
  getAIEngineChatHistoryInfoRequest,
  updateAIEngineThreadResponse,
  updateAIEngineThreadFailure,
  updateAIEngineThreadRequest,
  sendAIEngineCardFailure,
  sendAIEngineCardResponse
} from 'actions';
import {
  AI_ENGINE_CARD_REQUEST,
  AI_ENGINE_CREATE_THREAD_REQUEST,
  AI_ENGINE_DELETE_THREAD_REQUEST,
  AI_ENGINE_GET_CHAT_HISTORY_INFO_REQUEST,
  AI_ENGINE_GET_CHAT_HISTORY_REQUEST,
  AI_ENGINE_LIST_THREAD_REQUEST,
  AI_ENGINE_SEND_NEW_CHAT_REQUEST,
  AI_ENGINE_UPDATE_THREAD_REQUEST
} from 'actions/action-types';
import { commonApiTypes } from 'types';
import { modifiedMessages } from 'utils/helpers/ai-engine-helpers';
import { AI_ENGINE_API, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG, AI_ENGINE_NEW_CHAT_FAILURE_MSG, ALERT_BOX_TYPES, API, apiCall, defaultHeader, initialMsg } from 'utils';
import { alertBoxCall } from 'components';
import { store } from 'store';
import { config } from 'config';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendAIEngineChatRequest({ payload }: ISagaPayload): any {
  try {
    const { message, threadId, userOwnership, id, assistantIndex, activeUserToken, isInitialCall, isCardDetail } = payload;
    const headers = defaultHeader();

    const response: Response = yield call(fetch, `${config.apiAiEngineBaseUrl}${AI_ENGINE_API.newChatRequest.apiPath}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        message,
        thread_id: threadId,
        structured_format: message === initialMsg ? true : false,
        user_info: {
          [userOwnership]: id,
          token: activeUserToken
        }
      })
    });

    if (!response.body) {
      throw new Error('ReadableStream is not supported or empty response body');
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let assistantResponse = '';
    let buffer = '';

    let done = false;
    while (!done) {
      const { value, done: doneReading } = yield call([reader, reader.read]);
      done = doneReading;

      if (!value) continue;

      const chunkValue = decoder.decode(value, { stream: true });
      buffer += chunkValue;

      const lines = buffer.split('\n');
      buffer = lines.pop() || ''; // Keep any incomplete JSON chunk

      for (const line of lines) {
        if (!line.trim()) continue;

        try {
          const parsed = JSON.parse(line.trim());

          if (parsed.type === 'thread_created' && !threadId) {
            yield put(getAIEngineChatHistoryInfoRequest({ thread_id: parsed.data.thread_id }));
          }
          if (parsed.event === 'thread.run.step.delta') {
            let messages = '';

            for (const toolCall of parsed.data.delta.step_details.tool_calls) {
              const msg = toolCall.type === 'function' ? 'Connecting to rallio...' : toolCall.type === 'code_interpreter' ? 'Analyzing information...' : 'Processing...';

              if (assistantResponse && !assistantResponse.includes(`$$$${msg}$$$`)) {
                assistantResponse += `$$$${msg}$$$`;
              }
              messages = msg;
            }
            yield put(updateChatHistory({ assistantIndex, message: assistantResponse }));
            yield put(aiEngineGeneratingMsg(messages));
          }

          if (parsed.event === 'thread.run.step.completed') {
            if (/\$\$\$([\s\S]*?)\$\$\$/g.test(assistantResponse)) {
              assistantResponse = assistantResponse.replace(/\$\$\$([\s\S]*?)\$\$\$/g, '');
              yield put(updateChatHistory({ assistantIndex, message: assistantResponse }));
            }
            yield put(aiEngineGeneratingMsg(''));
          }
          if (parsed.event === 'thread.message.delta') {
            for (const content of parsed.data.delta.content) {
              assistantResponse = modifiedMessages(content, assistantResponse);
            }
            if (!isInitialCall) {
              yield put(updateChatHistory({ assistantIndex, message: assistantResponse }));
            }
          }
        } catch (error) {
          yield put(sendAIEngineNewChatFailure(AI_ENGINE_NEW_CHAT_FAILURE_MSG));
        }
      }
    }

    if (!isCardDetail) {
      yield put(sendAIEngineNewChatResponse({ assistantIndex, status: 'completed' }));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_NEW_CHAT_FAILURE_MSG);
    yield put(sendAIEngineNewChatFailure(AI_ENGINE_NEW_CHAT_FAILURE_MSG));
  }
}

function* sendAIEngineCardRequest({ payload }: ISagaPayload): any {
  try {
    const { userOwnership, id, activeUserToken } = payload;
    const headers = defaultHeader();
    const response: Response = yield call(fetch, `${config.apiAiEngineBaseUrl}cards`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        user_info: {
          [userOwnership]: String(id),
          token: activeUserToken
        }
      })
    });
    const responseData = yield response.json();
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(sendAIEngineCardResponse({ cardContent: responseData?.cards, isCardLoading: false }));
      localStorage.setItem('lastRunDate', moment().format('L'));
    } else {
      yield put(sendAIEngineCardFailure(response.url));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(sendAIEngineCardFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

function* sendGetAIEngineChatHistoryRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield call(fetch, `${config.apiAiEngineBaseUrl}threads/${payload}/messages`, {
      method: 'GET',
      headers
    });
    const responseData = yield response.json();
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAIEngineChatHistoryResponse(responseData));
    } else {
      yield put(getAIEngineChatHistoryFailure(response.url));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(getAIEngineChatHistoryFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

function* sendGetAIEngineChatHistoryInfoRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield call(fetch, `${config.apiAiEngineBaseUrl}chat_title`, {
      method: 'POST',
      body: JSON.stringify({ thread_id: payload.thread_id }),
      headers
    });
    const responseData = yield response.json();

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAIEngineChatHistoryInfoResponse(responseData));
      let threadList = store.getState().aiEngine.threadList;
      let threadId = store.getState().aiEngine.threadId;
      let chatTitle = store.getState().aiEngine.chatTitle;
      store.subscribe(() => {
        threadList = store.getState().aiEngine.threadList;
        threadId = store.getState().aiEngine.threadId;
        chatTitle = store.getState().aiEngine.chatTitle;
      });
      if (chatTitle) {
        const uniqueId = threadList?.filter((it: any) => it.thread_id === threadId)[0]?.id;
        if (uniqueId) {
          yield put(updateAIEngineThreadRequest({ id: uniqueId, openai_chat_thread: { title: responseData.chat_history_title } }));
        }
      }
    } else {
      yield put(getAIEngineChatHistoryInfoFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(getAIEngineChatHistoryInfoFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

function* createAIEngineThreadRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params: payload, ...API.createThreadRequest });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 201) {
      yield put(createAIEngineThreadResponse(response.data));
    } else {
      yield put(createAIEngineThreadFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(createAIEngineThreadFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

function* updateAIEngineThreadTitleRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      data: { openai_chat_thread: payload.openai_chat_thread },
      apiPath: API.updateThreadRequest.apiPath.replace(':id', payload.id),
      action: API.updateThreadRequest.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateAIEngineThreadResponse(response.data));
    } else {
      yield put(updateAIEngineThreadFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(updateAIEngineThreadFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

function* getAIEngineThreadListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params: payload, ...API.getThreadListRequest });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAIEngineThreadListResponse(response.data.openai_chat_threads));
    } else {
      yield put(getAIEngineThreadListFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(getAIEngineThreadListFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

function* deleteAIEngineThreadRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, apiPath: API.deleteThreadRequest.apiPath.replace(':id', payload), action: API.deleteThreadRequest.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(deleteAIEngineThreadResponse(payload));
    } else {
      yield put(deleteAIEngineThreadFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(deleteAIEngineThreadFailure(AI_ENGINE_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

export function* takeAIEngineRequest() {
  yield takeLatest(AI_ENGINE_CARD_REQUEST, sendAIEngineCardRequest);
  yield takeLatest(AI_ENGINE_SEND_NEW_CHAT_REQUEST, sendAIEngineChatRequest);
  yield takeLatest(AI_ENGINE_GET_CHAT_HISTORY_REQUEST, sendGetAIEngineChatHistoryRequest);
  yield takeLatest(AI_ENGINE_GET_CHAT_HISTORY_INFO_REQUEST, sendGetAIEngineChatHistoryInfoRequest);
  yield takeLatest(AI_ENGINE_CREATE_THREAD_REQUEST, createAIEngineThreadRequest);
  yield takeLatest(AI_ENGINE_UPDATE_THREAD_REQUEST, updateAIEngineThreadTitleRequest);
  yield takeLatest(AI_ENGINE_LIST_THREAD_REQUEST, getAIEngineThreadListRequest);
  yield takeLatest(AI_ENGINE_DELETE_THREAD_REQUEST, deleteAIEngineThreadRequest);
}
