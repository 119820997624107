import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';

import { aiEngineApiTypes } from 'types/ai-engine';

// AI ENGINE - CARD
export const sendAIEngineCardRequest = (payload: aiEngineApiTypes.ICreateAIEngineRequest) => createAction(actionTypes.AI_ENGINE_CARD_REQUEST, payload);
export const sendAIEngineCardResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_CARD_RESPONSE, payload);
export const sendAIEngineCardFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_CARD_FAILURE, error);

// AI ENGINE - CREATE CHAT HISTORY
export const sendAIEngineNewChatRequest = (payload: aiEngineApiTypes.ICreateAIEngineRequest) => createAction(actionTypes.AI_ENGINE_SEND_NEW_CHAT_REQUEST, payload);
export const sendAIEngineNewChatResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_SEND_NEW_CHAT_RESPONSE, payload);
export const sendAIEngineNewChatFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_SEND_NEW_CHAT_FAILURE, error);

// AI ENGINE - GET CHAT HISTORY
export const getAIEngineChatHistoryRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_REQUEST, payload);
export const getAIEngineChatHistoryResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_RESPONSE, payload);
export const getAIEngineChatHistoryFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_FAILURE, error);

// AI ENGINE - GET CHAT HISTORY INFO
export const getAIEngineChatHistoryInfoRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_INFO_REQUEST, payload);
export const getAIEngineChatHistoryInfoResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_INFO_RESPONSE, payload);
export const getAIEngineChatHistoryInfoFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_INFO_FAILURE, error);

// AI ENGINE - CREATE THREAD
export const createAIEngineThreadRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_CREATE_THREAD_REQUEST, payload);
export const createAIEngineThreadResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_CREATE_THREAD_RESPONSE, payload);
export const createAIEngineThreadFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_CREATE_THREAD_FAILURE, error);

// AI ENGINE - UPDATE THREAD
export const updateAIEngineThreadRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_UPDATE_THREAD_REQUEST, payload);
export const updateAIEngineThreadResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_UPDATE_THREAD_RESPONSE, payload);
export const updateAIEngineThreadFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_UPDATE_THREAD_FAILURE, error);

// AI ENGINE - LIST THREAD
export const getAIEngineThreadListRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_LIST_THREAD_REQUEST, payload);
export const getAIEngineThreadListResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_LIST_THREAD_RESPONSE, payload);
export const getAIEngineThreadListFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_LIST_THREAD_FAILURE, error);

// AI ENGINE - DELETE THREAD
export const deleteAIEngineThreadRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_DELETE_THREAD_REQUEST, payload);
export const deleteAIEngineThreadResponse = (payload: { message: string }) => createAction(actionTypes.AI_ENGINE_DELETE_THREAD_RESPONSE, payload);
export const deleteAIEngineThreadFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_DELETE_THREAD_FAILURE, error);

// AI ENGINE - UPDATE CHAT HISTORY
export const updateChatHistory = (payload: any) => createAction(actionTypes.UPDATE_CHAT_HISTORY, payload);

// AI ENGINE - THREAD ID
export const setThreadId = (payload: string) => createAction(actionTypes.SET_THREAD_ID, payload);

// AI ENGINE -GENERATING MESSAGE
export const aiEngineGeneratingMsg = (payload: string) => createAction(actionTypes.AI_ENGINE_GENERATING_MESSAGE, payload);

// AI ENGINE - RESET
export const aiEngineReset = (payload?: any) => createAction(actionTypes.AI_ENGINE_RESET, payload);

const AI_ENGINE_ACTIONS = {
  sendAIEngineCardRequest,
  sendAIEngineCardResponse,
  sendAIEngineCardFailure,
  getAIEngineChatHistoryRequest,
  getAIEngineChatHistoryResponse,
  getAIEngineChatHistoryFailure,
  sendAIEngineNewChatRequest,
  sendAIEngineNewChatResponse,
  sendAIEngineNewChatFailure,
  getAIEngineChatHistoryInfoRequest,
  getAIEngineChatHistoryInfoResponse,
  getAIEngineChatHistoryInfoFailure,
  createAIEngineThreadRequest,
  createAIEngineThreadResponse,
  createAIEngineThreadFailure,
  getAIEngineThreadListRequest,
  getAIEngineThreadListResponse,
  getAIEngineThreadListFailure,
  updateAIEngineThreadRequest,
  updateAIEngineThreadResponse,
  updateAIEngineThreadFailure,
  deleteAIEngineThreadRequest,
  deleteAIEngineThreadResponse,
  deleteAIEngineThreadFailure,
  updateChatHistory,
  setThreadId,
  aiEngineGeneratingMsg,
  aiEngineReset
};

export type AiEngineActions = ActionsUnion<typeof AI_ENGINE_ACTIONS>;
