import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { AiEngineActions } from 'actions';
import { aiEngineReducerTypes } from 'types';
import { modifiedMessages } from 'utils/helpers/ai-engine-helpers';

type IAIEngineReducer = aiEngineReducerTypes.IAIEngineReducerType;

export const initialState: IAIEngineReducer = {
  error: null,
  isChatHistoryFetching: false,
  chatHistory: [],
  cardDetails: {
    cardContent: [],
    isCardLoading: false
  },
  threadId: '',
  threadList: [],
  processingMsg: '',
  chatTitle: ''
};

const aiEngineReducer: Reducer<IAIEngineReducer, AiEngineActions> = (state: IAIEngineReducer = initialState, action: AiEngineActions): IAIEngineReducer => {
  switch (action.type) {
    case types.AI_ENGINE_CARD_REQUEST:
      return {
        ...state,
        cardDetails: {
          ...state.cardDetails,
          isCardLoading: true
        },
        processingMsg: 'Just a sec...'
      };
    case types.AI_ENGINE_CARD_RESPONSE:
      return {
        ...state,
        cardDetails: {
          cardContent: action.payload.cardContent,
          isCardLoading: action.payload.isCardLoading
        }
      };
    case types.AI_ENGINE_CARD_FAILURE:
      return {
        ...state,
        chatHistory: [],
        cardDetails: {
          ...state.cardDetails,
          isCardLoading: false
        },
        processingMsg: ''
      };
    case types.AI_ENGINE_SEND_NEW_CHAT_REQUEST:
      return {
        ...state,
        chatHistory: action.payload.isCardDetail
          ? [{ role: 'assistant', message: '', status: 'in_progress' }]
          : [...(state.chatHistory ? state.chatHistory : []), { role: 'user', message: action.payload.message }, { role: 'assistant', message: '', status: 'in_progress' }],
        processingMsg: 'Just a sec...'
      };
    case types.AI_ENGINE_SEND_NEW_CHAT_RESPONSE:
      return {
        ...state,
        chatHistory:
          action.payload.assistantIndex >= 0
            ? state.chatHistory.map((msg, index) => (index === action.payload.assistantIndex ? { ...msg, message: action.payload.message || msg.message, status: action.payload.status || '' } : msg))
            : action.payload,
        processingMsg: ''
      };
    case types.AI_ENGINE_SEND_NEW_CHAT_FAILURE:
      return {
        ...state,
        chatHistory: [],
        cardDetails: {
          ...state.cardDetails,
          isCardLoading: false
        },
        processingMsg: ''
      };

    case types.AI_ENGINE_GET_CHAT_HISTORY_REQUEST:
      return {
        ...state,
        chatHistory: [],
        isChatHistoryFetching: true,
        cardDetails: {
          ...state.cardDetails,
          isCardLoading: false
        }
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_RESPONSE:
      return {
        ...state,
        chatHistory: action.payload?.data.reverse().map((it: any) => ({
          role: it.role,
          message: it.content.map((item: any) => modifiedMessages(item)).join(' ')
        })),
        isChatHistoryFetching: false
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_FAILURE:
      return {
        ...state,
        chatHistory: [],
        isChatHistoryFetching: false,
        error: action.payload
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_INFO_REQUEST:
      return {
        ...state,
        threadId: action.payload.thread_id
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_INFO_RESPONSE:
      return {
        ...state,
        chatTitle: action.payload.chat_history_title
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_INFO_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.AI_ENGINE_CREATE_THREAD_REQUEST:
      return {
        ...state
      };
    case types.AI_ENGINE_CREATE_THREAD_RESPONSE:
      return {
        ...state,
        threadList: [action.payload, ...state.threadList]
      };
    case types.AI_ENGINE_CREATE_THREAD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.AI_ENGINE_UPDATE_THREAD_REQUEST:
      return {
        ...state
      };
    case types.AI_ENGINE_UPDATE_THREAD_RESPONSE:
      return {
        ...state,
        threadList: state.threadList.map((it: any) => (it.thread_id === action.payload.thread_id ? { ...it, title: action.payload.title } : it))
      };
    case types.AI_ENGINE_UPDATE_THREAD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.AI_ENGINE_LIST_THREAD_REQUEST:
      return {
        ...state,
        threadList: state.threadList
      };
    case types.AI_ENGINE_LIST_THREAD_RESPONSE:
      return {
        ...state,
        threadList: action.payload
      };
    case types.AI_ENGINE_LIST_THREAD_FAILURE:
      return {
        ...state,
        threadList: []
      };
    case types.AI_ENGINE_DELETE_THREAD_REQUEST:
      return {
        ...state
      };
    case types.AI_ENGINE_DELETE_THREAD_RESPONSE:
      return {
        ...state,
        threadList: state.threadList.filter((it) => it.thread_id !== action.payload),
        chatHistory: []
      };
    case types.AI_ENGINE_DELETE_THREAD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.UPDATE_CHAT_HISTORY:
      return {
        ...state,
        chatHistory:
          action.payload.assistantIndex >= 0
            ? state.chatHistory.map((msg, index) => (index === action.payload.assistantIndex ? { ...msg, message: action.payload.message || msg.message, status: action.payload.status || '' } : msg))
            : action.payload
      };
    case types.SET_THREAD_ID:
      return {
        ...state,
        threadId: action.payload
      };
    case types.AI_ENGINE_GENERATING_MESSAGE:
      return {
        ...state,
        processingMsg: action.payload
      };
    case types.AI_ENGINE_RESET:
      const newState = action.payload ? { ...state, ...action.payload } : { ...initialState };
      return { ...newState };
    default:
      return state;
  }
};

export { aiEngineReducer };
