import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import { ImageValidation } from 'widgets/Media';
import { CommonFilter } from 'components';
import { deleteAIEngineThreadRequest, getAIEngineChatHistoryRequest, getAIEngineThreadListRequest, setThreadId } from 'actions';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { IStore, aiEngineContainerTypes } from 'types';
import { chatsBasedOnDateGrouping } from 'utils/helpers';
import { IFiterHandleChangeData } from 'types/common/filter';
import { handleFilterChange } from 'analytics/utils';

export const AIEngineFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useAccountSwitcherData();

  const threadList = useSelector((state: IStore) => state.aiEngine.threadList);
  const threadId = useSelector((state: IStore) => state.aiEngine.threadId);
  const { queryParams, filter } = useParamsDeconstructor({ threadId: '' });

  const aiEngineFilter = {
    ...filter,
    threadId: queryParams?.threadId || ''
  };

  useEffect(() => {
    dispatch(getAIEngineThreadListRequest({ user_id: id }));
  }, [id, dispatch]);

  const handleChange = useCallback(
    (data: IFiterHandleChangeData, isClear = false) => {
      const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
      if (data.threadId) {
        queryParamsClone.threadId = data.threadId;
      } else {
        delete queryParamsClone.threadId;
      }
      navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
    },
    [id, navigate, queryParams]
  );

  useEffect(() => {
    if (queryParams.threadId && queryParams.threadId !== threadId) {
      dispatch(setThreadId(queryParams.threadId));
      dispatch(getAIEngineChatHistoryRequest(queryParams.threadId));
    }
  }, [queryParams.threadId, threadId, dispatch]);

  useEffect(() => {
    if (!queryParams.threadId && threadId) {
      handleChange({ threadId });
    }
  }, [threadId, handleChange, queryParams.threadId]);

  const ChatHistory = useCallback(() => {
    const categorizedChats = chatsBasedOnDateGrouping(threadList);

    const renderChats = (threads: aiEngineContainerTypes.IChatThread[], category: string) => {
      return (
        threads.length >= 1 && (
          <div key={category} className="chat-item ch-options">
            <h2 className="dayStamp">{category}</h2>
            <div className="choGroup">
              {threads.map((it, index) => (
                <h4 key={it.id} className={`chat-name ${it.thread_id === threadId ? 'cn-active' : ''}`}>
                  <span
                    className="ch-desc"
                    onClick={() => {
                      handleChange({ threadId: it.thread_id });
                    }}
                  >
                    {it.title || 'New Chat'}
                  </span>
                  <DropdownButton
                    as={ButtonGroup}
                    key={index}
                    id={`dropdown-variants-${index}`}
                    variant={'success'}
                    title={<ImageValidation isImgValid defaultImg="more-ai" customClassname="ai-ch-more" customName={'More'} />}
                    className="ch-more-options"
                  >
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setThreadId(''));
                        dispatch(deleteAIEngineThreadRequest(it.thread_id));
                        handleChange({ threadId: null });
                      }}
                    >
                      <ImageValidation isImgValid defaultImg="delete-lined-red" customClassname="ai-chm-item ai-chm-del" customName={'Delete'} />
                      <span>Delete</span>
                    </Dropdown.Item>
                  </DropdownButton>
                </h4>
              ))}
            </div>
          </div>
        )
      );
    };

    return (
      <div className="chat-history">
        <h2 className="chat-title">Chat History</h2>
        <div className="chat-group">
          {Object.entries(categorizedChats).map(([category, data], index) => (
            <div className="cg-sections" key={`${category}-${index}`}>
              {Array.isArray(data)
                ? renderChats(data, category)
                : Object.entries(data)
                    .sort(([a], [b]) => moment(b, 'MMMM YYYY').valueOf() - moment(a, 'MMMM YYYY').valueOf())
                    .map(([subCategory, subChats]) => renderChats(subChats, subCategory))}
            </div>
          ))}
        </div>
      </div>
    );
  }, [threadList, threadId, handleChange, dispatch]);

  return <CommonFilter filter={aiEngineFilter} handleChange={handleChange} isDateRangeFilterEnable={false} sectionVariant="adv-ov-filter" renderPrefixData={ChatHistory} />;
};
